<template>
  <b-modal
    id="modal-kunden-cleaning-overwrite"
    size="lg"
    scrollable
    centered
    :title="$t('KUNDEN.DUBLETTEN.CLEANING.OVERWRITEMODAL.TITLE')"
    @hidden="onHide($event)"
  >
    <div class="row">
      <div class="col-md-6">
        <p>{{ $t('KUNDEN.DUBLETTEN.CLEANING.OVERWRITEMODAL.ORGINALDATEN') }}</p>
        <b-form ref="form" v-if="kunde">
          <b-form-group label-cols-sm="1" label-size="sm" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group
              :label="$t('COMMON.STRASSE') + ':'"
              label-for="nested-strasse"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                disabled
                id="nested-strasse"
                :value="kunde.adresse.valueObject.strasse"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Ort:" label-for="nested-ort" label-cols-sm="3" label-align-sm="right">
              <b-form-input
                disabled
                id="nested-city"
                :value="kunde.adresse.valueObject.ort"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('COMMON.PLZ') + ':'"
              label-for="nested-plz"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                disabled
                id="nested-plz"
                :value="kunde.adresse.valueObject.postleitzahl"
                trim
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('REISEN.COMMON.COUNTRY') + ':'"
              label-for="nested-land"
              class="mb-0"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="nested-land"
                disabled
                :value="kunde.adresse.valueObject.land"
                trim
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-form>
      </div>

      <div class="col-md-6">
        <p>Änderungen:</p>
        <b-form ref="form" v-if="kunde">
          <b-form-group label-cols-sm="1" label-size="sm" label-class="font-weight-bold pt-0" class="mb-0">
            <b-form-group
              :label="$t('COMMON.STRASSE') + ':'"
              label-for="nested-strasse"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="nested-strasse"
                :state="
                  $v.kunde.adresse.validationObject.strasse.$dirty
                    ? !$v.kunde.adresse.validationObject.strasse.$error
                    : null
                "
                v-model="$v.kunde.adresse.validationObject.strasse.$model"
                trim
                @update="onInputUpdate({ strasse: $event })"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('COMMON.ORT') + ':'"
              label-for="nested-ort"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="nested-city"
                :state="
                  $v.kunde.adresse.validationObject.ort.$dirty
                    ? !$v.kunde.adresse.validationObject.ort.$error
                    : null
                "
                v-model="$v.kunde.adresse.validationObject.ort.$model"
                trim
                @update="onInputUpdate({ ort: $event })"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('COMMON.PLZ') + ':'"
              label-for="nested-plz"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="nested-plz"
                :state="
                  $v.kunde.adresse.validationObject.postleitzahl.$dirty
                    ? !$v.kunde.adresse.validationObject.postleitzahl.$error
                    : null
                "
                v-model="$v.kunde.adresse.validationObject.postleitzahl.$model"
                trim
                @update="onInputUpdate({ postleitzahl: $event })"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('REISEN.COMMON.COUNTRY') + ':'"
              label-for="nested-land"
              class="mb-0"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input
                id="nested-land"
                :state="
                  $v.kunde.adresse.validationObject.land.$dirty
                    ? !$v.kunde.adresse.validationObject.land.$error
                    : null
                "
                v-model="$v.kunde.adresse.validationObject.land.$model"
                trim
                @blur="$v.kunde.adresse.validationObject.land.$touch()"
                @update="onInputUpdate({ land: $event })"
              ></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-form>
      </div>

      <div class="col-md-12 mt-2 mb-0 text-center">
        <p v-if="$v.kunde.adresse.validationObject.strasse.$error" class="text-danger">
          Strasse wird benötigt!
        </p>
        <p v-if="$v.kunde.adresse.validationObject.ort.$error" class="text-danger">Ort wird benötigt!</p>
        <p v-if="$v.kunde.adresse.validationObject.postleitzahl.$error" class="text-danger">
          Postleitzahl wird benötigt und kann nur aus Zahlen bestehen!
        </p>
        <p v-if="$v.kunde.adresse.validationObject.land.$error" class="text-danger">Land wird benötigt!</p>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="success" @click="onModalOkay()">Ändern und Übernehmen</b-button>
      <b-button variant="danger" @click="cancel()">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import { numeric, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  props: {
    kunde: { type: Object },
  },
  data() {
    return {
      changedKunde: null,
    };
  },
  validations: {
    kunde: {
      adresse: {
        validationObject: {
          strasse: { required },
          ort: { required },
          postleitzahl: { required, numeric },
          land: { required },
        },
      },
    },
  },
  watch: {
    kunde() {
      this.changedKunde = null;
      this.$v.$touch();
    },
  },
  methods: {
    onInputUpdate(changeObject) {
      this.changedKunde = { ...this.changedKunde, ...changeObject };
    },
    async onModalOkay() {
      if (this.$v.$anyError) {
        this.toast('Bitte behebe alle Fehler in der Eingabe-Form.', { variant: 'danger' });
      } else {
        if (this.changedKunde) {
          this.$emit('onModalSubmit', { ...this.changedKunde, id: this.kunde.id });
        } else {
          this.$emit('onModalSubmit', null);
        }
      }
    },
    onHide($event) {
      this.$emit('onModalHide', $event);
    },
  },
};
</script>
